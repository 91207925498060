<template>
  <v-app>
    <pages-core-app-bar v-if="$route.name === 'PopGallery'" />

    <pages-core-drawer :expand-on-hover.sync="expandOnHover" v-if="$route.name === 'PopGallery'" />

    <pages-core-view />

    <pages-core-footer v-if="showFooter" />
  </v-app>
</template>

<script>
export default {
  name: "PagesIndex",

  data: () => ({
    expandOnHover: false,
  }),

  components: {
    PagesCoreAppBar: () => import("@/components/_view_handler/public/AppBar"),
    PagesCoreFooter: () => import("@/components/_view_handler/public/Footer"),
    PagesCoreDrawer: () => import('@/components/_view_handler/public/Drawer'),
    PagesCoreView: () => import("@/components/_view_handler/public/View"),
  },

  computed: {
    showFooter() {
      let result = false
      const routes = ['404', 'Download', 'VerifyCostCalculator']

      // Loop through routes to see if it matches current route
      routes.forEach(route => {
        if(this.$route.name === route)
          result = true
      })
      
      // Negate result so boolean makes sense for function name
      return !result
    }
  },
};
</script>
